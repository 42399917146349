/*
 * AUTOGENERATED - DON'T EDIT
 * Your edits in this file will be overwritten in the next build!
 * Modify the docusaurus.config.js file at your site's root instead.
 */
export default {
  "title": "Dockview",
  "tagline": "A zero dependency layout manager supporting ReactJS and Vanilla TypeScript",
  "url": "https://dockview.dev",
  "baseUrl": "/",
  "onBrokenLinks": "throw",
  "onBrokenMarkdownLinks": "warn",
  "favicon": "img/dockview_logo.ico",
  "organizationName": "mathuo",
  "projectName": "dockview",
  "i18n": {
    "defaultLocale": "en",
    "locales": [
      "en"
    ],
    "path": "i18n",
    "localeConfigs": {}
  },
  "stylesheets": [
    {
      "href": "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    },
    {
      "href": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap\" rel=\"stylesheet\""
    }
  ],
  "plugins": [
    "docusaurus-plugin-sass",
    null
  ],
  "presets": [
    [
      "classic",
      {
        "docs": {
          "sidebarPath": "/home/runner/work/dockview/dockview/packages/docs/sidebars.js"
        },
        "blog": {},
        "theme": {
          "customCss": "/home/runner/work/dockview/dockview/packages/docs/src/css/custom.scss"
        },
        "gtag": {
          "trackingID": "G-KXGC1C9ZHC"
        }
      }
    ]
  ],
  "themeConfig": {
    "metadata": [
      {
        "name": "keywords",
        "content": "react ,components ,typescript ,drag-and-drop ,reactjs ,layout ,drag ,drop ,tabs ,dock ,docking ,splitter ,docking-library ,layout-manager ,docking-layout"
      }
    ],
    "docs": {
      "sidebar": {
        "autoCollapseCategories": true,
        "hideable": false
      },
      "versionPersistence": "localStorage"
    },
    "navbar": {
      "title": "Dockview",
      "logo": {
        "alt": "My Site Logo",
        "src": "img/dockview_logo.svg"
      },
      "items": [
        {
          "type": "doc",
          "docId": "overview/getStarted/installation",
          "position": "left",
          "label": "Docs"
        },
        {
          "type": "docSidebar",
          "position": "left",
          "sidebarId": "api",
          "label": "API"
        },
        {
          "to": "/blog",
          "label": "Blog",
          "position": "left"
        },
        {
          "to": "/demo",
          "label": "Demo",
          "position": "left"
        },
        {
          "href": "https://github.com/mathuo/dockview",
          "position": "right",
          "className": "header-github-link",
          "aria-label": "GitHub repository"
        }
      ],
      "hideOnScroll": false
    },
    "footer": {
      "style": "dark",
      "links": [
        {
          "title": "Learn",
          "items": [
            {
              "label": "Demo",
              "to": "/demo"
            }
          ]
        },
        {
          "title": "Community",
          "items": [
            {
              "label": "Stack Overflow",
              "href": "https://stackoverflow.com/questions/tagged/dockview"
            }
          ]
        },
        {
          "title": "More",
          "items": [
            {
              "label": "Blog",
              "to": "/blog"
            },
            {
              "label": "GitHub",
              "href": "https://github.com/mathuo/dockview"
            }
          ]
        }
      ],
      "copyright": "Copyright © 2025 Dockview, Inc. Built with Docusaurus."
    },
    "prism": {
      "theme": {
        "plain": {
          "color": "#403f53",
          "backgroundColor": "#FBFBFB"
        },
        "styles": [
          {
            "types": [
              "changed"
            ],
            "style": {
              "color": "rgb(162, 191, 252)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "deleted"
            ],
            "style": {
              "color": "rgba(239, 83, 80, 0.56)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "inserted",
              "attr-name"
            ],
            "style": {
              "color": "rgb(72, 118, 214)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "comment"
            ],
            "style": {
              "color": "rgb(152, 159, 177)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "string",
              "builtin",
              "char",
              "constant",
              "url"
            ],
            "style": {
              "color": "rgb(72, 118, 214)"
            }
          },
          {
            "types": [
              "variable"
            ],
            "style": {
              "color": "rgb(201, 103, 101)"
            }
          },
          {
            "types": [
              "number"
            ],
            "style": {
              "color": "rgb(170, 9, 130)"
            }
          },
          {
            "types": [
              "punctuation"
            ],
            "style": {
              "color": "rgb(153, 76, 195)"
            }
          },
          {
            "types": [
              "function",
              "selector",
              "doctype"
            ],
            "style": {
              "color": "rgb(153, 76, 195)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "class-name"
            ],
            "style": {
              "color": "rgb(17, 17, 17)"
            }
          },
          {
            "types": [
              "tag"
            ],
            "style": {
              "color": "rgb(153, 76, 195)"
            }
          },
          {
            "types": [
              "operator",
              "property",
              "keyword",
              "namespace"
            ],
            "style": {
              "color": "rgb(12, 150, 155)"
            }
          },
          {
            "types": [
              "boolean"
            ],
            "style": {
              "color": "rgb(188, 84, 84)"
            }
          }
        ]
      },
      "darkTheme": {
        "plain": {
          "color": "#bfc7d5",
          "backgroundColor": "#292d3e"
        },
        "styles": [
          {
            "types": [
              "comment"
            ],
            "style": {
              "color": "rgb(105, 112, 152)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "string",
              "inserted"
            ],
            "style": {
              "color": "rgb(195, 232, 141)"
            }
          },
          {
            "types": [
              "number"
            ],
            "style": {
              "color": "rgb(247, 140, 108)"
            }
          },
          {
            "types": [
              "builtin",
              "char",
              "constant",
              "function"
            ],
            "style": {
              "color": "rgb(130, 170, 255)"
            }
          },
          {
            "types": [
              "punctuation",
              "selector"
            ],
            "style": {
              "color": "rgb(199, 146, 234)"
            }
          },
          {
            "types": [
              "variable"
            ],
            "style": {
              "color": "rgb(191, 199, 213)"
            }
          },
          {
            "types": [
              "class-name",
              "attr-name"
            ],
            "style": {
              "color": "rgb(255, 203, 107)"
            }
          },
          {
            "types": [
              "tag",
              "deleted"
            ],
            "style": {
              "color": "rgb(255, 85, 114)"
            }
          },
          {
            "types": [
              "operator"
            ],
            "style": {
              "color": "rgb(137, 221, 255)"
            }
          },
          {
            "types": [
              "boolean"
            ],
            "style": {
              "color": "rgb(255, 88, 116)"
            }
          },
          {
            "types": [
              "keyword"
            ],
            "style": {
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "doctype"
            ],
            "style": {
              "color": "rgb(199, 146, 234)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "namespace"
            ],
            "style": {
              "color": "rgb(178, 204, 214)"
            }
          },
          {
            "types": [
              "url"
            ],
            "style": {
              "color": "rgb(221, 221, 221)"
            }
          }
        ]
      },
      "additionalLanguages": [
        "markdown",
        "latex"
      ],
      "magicComments": [
        {
          "className": "theme-code-block-highlighted-line",
          "line": "highlight-next-line",
          "block": {
            "start": "highlight-start",
            "end": "highlight-end"
          }
        },
        {
          "className": "code-block-error-line",
          "line": "This will error"
        }
      ]
    },
    "announcementBar": {
      "id": "announcementBar",
      "content": "⭐️ If you like Dockview, give it a star on <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://github.com/mathuo/dockview\">GitHub</a>",
      "isCloseable": true
    },
    "tableOfContents": {
      "maxHeadingLevel": 5,
      "minHeadingLevel": 2
    },
    "colorMode": {
      "defaultMode": "light",
      "disableSwitch": false,
      "respectPrefersColorScheme": false
    }
  },
  "baseUrlIssueBanner": true,
  "onBrokenAnchors": "warn",
  "onDuplicateRoutes": "warn",
  "staticDirectories": [
    "static"
  ],
  "customFields": {},
  "themes": [],
  "scripts": [],
  "headTags": [],
  "clientModules": [],
  "titleDelimiter": "|",
  "noIndex": false,
  "markdown": {
    "format": "mdx",
    "mermaid": false,
    "mdx1Compat": {
      "comments": true,
      "admonitions": true,
      "headingIds": true
    }
  }
};
